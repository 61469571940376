import React from 'react';
import { Cards } from '@amzn/awsui-components-react';
import { css, cx } from 'emotion';
import { injectIntl } from 'react-intl';
import { colors, typographyStyles, sizing } from '../../util/Styles';
import { messages } from '../../intl';
import { cardHeaderStyles, orderCountStyles } from './styles';

const concessionValueStyles = cx(
    typographyStyles.fontSize3,
    css`
        color: ${colors.grey900};
        padding-bottom: ${sizing.xsmall};
    `,
);

const AbuseStats = ({
    currency,
    dnr,
    intl,
    mdr,
    returnlessRefunds,
    returns,
}) => (
    <Cards
        cardDefinition={{
            header: item => <div className={cardHeaderStyles}>{item.title}</div>,
            sections: [{
                id: 'body',
                content: item => (
                    <div>
                        <div className={concessionValueStyles}>
                            {item.value ? intl.formatNumber(item.value, { style: 'currency', currency }) : intl.formatMessage(messages.null)}
                        </div>
                        <div className={orderCountStyles}>
                            {item.count && intl.formatMessage(messages.itemsCount, {
                                count: intl.formatNumber(item.count),
                            })}
                        </div>
                    </div>
                ),
            }],
        }}
        cardsPerRow={[{ cards: 2 }]}
        items={[
            {
                ...dnr,
                title: intl.formatMessage(messages.DELIVERED_NOT_RECEIVED),
            },
            {
                ...returns,
                title: intl.formatMessage(messages.returns),
            },
            {
                ...returnlessRefunds,
                title: intl.formatMessage(messages.RETURNLESS_REFUNDS),
            },
            {
                ...mdr,
                title: intl.formatMessage(messages.MATERIALLY_DIFFERENT_RETURNS),
            },
        ]}
    />

);

export default injectIntl(AbuseStats);
