import { startWorkflowWith, state, sinkState } from './builder';
import { SelectConcessionType } from '../stepComponents/SelectConcessionType';
import { ShouldConcessionTypeBeWrongItem } from '../stepComponents/ShouldConcessionTypeBeWrongItem';
import { FetchAndShowRisk } from '../stepComponents/FetchAndShowRisk';
import { IsConcessionValid } from '../stepComponents/IsConcessionValid';
import { DoARIReferral } from '../stepComponents/DoARIReferral';
import { conditionalEdge, edge } from './edge';
import { ShowApplyConsumerPolicy } from '../stepComponents/ShowApplyConsumerPolicy';
import { ShowConsultCASEPolicy } from '../stepComponents/ShowConsultCASEPolicy';
import { FetchAndShowPolicy } from '../stepComponents/FetchAndShowPolicy';
import { SendIDVerificationEmail } from '../stepComponents/SendIDVerificationEmail';
import { IsMultipleDamagedDefectiveItems } from '../stepComponents/IsMultipleDamagedDefectiveItems';
import { ShowDamagedDefectiveClarification } from '../stepComponents/ShowDamagedDefectiveClarification';
import { ShowConcessionContactReason } from '../stepComponents/ShowConcessionContactReason';
export const registeredStepComponents = {
    [SelectConcessionType.name]: SelectConcessionType,
    [FetchAndShowRisk.name]: FetchAndShowRisk,
    [IsConcessionValid.name]: IsConcessionValid,
    [ShouldConcessionTypeBeWrongItem.name]: ShouldConcessionTypeBeWrongItem,
    [ShowApplyConsumerPolicy.name]: ShowApplyConsumerPolicy,
    [ShowConsultCASEPolicy.name]: ShowConsultCASEPolicy,
    [DoARIReferral.name]: DoARIReferral,
    [FetchAndShowPolicy.name]: FetchAndShowPolicy,
    [SendIDVerificationEmail.name]: SendIDVerificationEmail,
    [IsMultipleDamagedDefectiveItems.name]: IsMultipleDamagedDefectiveItems,
    [ShowDamagedDefectiveClarification.name]: ShowDamagedDefectiveClarification,
    [ShowConcessionContactReason.name]: ShowConcessionContactReason,
};
export const policiesToTriggerSendIDVerificationEmail = [
    'CONFIRM_RETURN_AND_REQUEST_FOR_ID',
    'ID_REATTEMPT_PRIOR_ATTEMPTED_UNSUCCESSFUL',
    'ID_REATTEMPT_PRIOR_ATTEMPTED_ABANDONED',
    'ID_REATTEMPT_PRIOR_ATTEMPTED_INVALID',
    'ID_PENDING_VALIDATION',
];
export const getStateMachineConfig = (initialContext) => ({
    id: 'CAPWorkflow',
    ...startWorkflowWith(SelectConcessionType, initialContext),
    states: {
        ...state([
            edge({ from: SelectConcessionType, to: ShowConcessionContactReason })
        ]),
        ...state([
            conditionalEdge({ from: ShowConcessionContactReason, to: ShouldConcessionTypeBeWrongItem, condition: ({ selectedConcessionType }) => selectedConcessionType === 'itemMissing' }),
            conditionalEdge({ from: ShowConcessionContactReason, to: IsMultipleDamagedDefectiveItems, condition: ({ selectedConcessionType }) => selectedConcessionType === 'damagedDefective' }),
            edge({ from: ShowConcessionContactReason, to: FetchAndShowRisk }),
        ]),
        ...state([
            edge({ from: ShouldConcessionTypeBeWrongItem, to: FetchAndShowRisk }),
        ]),
        ...state([
            conditionalEdge({ from: IsMultipleDamagedDefectiveItems, to: ShowDamagedDefectiveClarification, condition: ({ isDamagedDefectiveItems }) => isDamagedDefectiveItems === true }),
            edge({ from: IsMultipleDamagedDefectiveItems, to: FetchAndShowRisk }),
        ]),
        ...state([
            edge({ from: ShowDamagedDefectiveClarification, to: FetchAndShowRisk }),
        ]),
        ...state([
            conditionalEdge({ from: FetchAndShowRisk, to: ShowApplyConsumerPolicy, condition: ({ riskLevel }) => riskLevel === 'NONE' }),
            edge({ from: FetchAndShowRisk, to: IsConcessionValid }),
        ]),
        ...state([
            edge({ from: IsConcessionValid, to: DoARIReferral }),
        ]),
        ...state([
            conditionalEdge({ from: DoARIReferral, to: ShowConsultCASEPolicy, condition: ({ isValidConcessionRequest }) => isValidConcessionRequest }),
            edge({ from: DoARIReferral, to: FetchAndShowPolicy }),
        ]),
        ...state([
            conditionalEdge({ from: FetchAndShowPolicy, to: SendIDVerificationEmail, condition: ({ policyId }) => policiesToTriggerSendIDVerificationEmail.includes(policyId) }),
        ]),
        ...sinkState(ShowApplyConsumerPolicy),
        ...sinkState(ShowConsultCASEPolicy),
        ...sinkState(SendIDVerificationEmail),
    },
});
